import { ROLES } from '@/model/shared/roles';
import { addDays } from '@/utils/dateTime/addDays';
import { TODAY } from '@/utils/dateTime/today';
import { CURRENT_WEEK, LAST_WEEK } from '@/utils/dateTime/week';
import { CURRENT_MONTH, LAST_MONTH } from '@/utils/dateTime/month';
import { BREAKDOWN_VALUES } from '@/model/modules/ecommerce/analytics/BreakdownValues';
import SelectOption from '@/model/shared/SelectOption';
import FILTERS_CONFIG from '@/model/modules/ecommerce/filtersConfig';
import Column from '@/model/shared/Column';
import { applyDateFormatters, stringToDate } from '@/filters/dateFilters';
import { monthDateFormatter } from '@/model/shared/DateFormatter';

const LIMIT_MIN_DATE = new Date(2000, 0, 1, 0, 0, 0, 0);

/*
 *
 * @param {string} columnKey - The column key
 * @param {string} propertyName - The property name
 * @param {array<string>} allowedRoles - The allowed roles
 * @returns  {object}
 */
function makeValidBlockedColumnGroup(columnKey, propertyName, { accumulated, valid, blocked }, sortInverted = false) {
  const title = columnKey.replace(/_/g, ' ');

  return {
    [columnKey]: new Column(title, `${propertyName}Accumulated`)
      .asSort(sortInverted)
      .setAllowedRoles(accumulated)
      .toRight()
      .setClass('bg-gray-400'),
    [`${columnKey}_VALID`]: new Column(`${title} VALID`, propertyName, false)
      .asSort(sortInverted)
      .setAllowedRoles(valid)
      .toRight()
      .setClass('bg-gray-400'),
    [`${columnKey}_BLOCKED`]: new Column(`${title} BLOCKED`, `${propertyName}Blocked`, false)
      .asSort(sortInverted)
      .setAllowedRoles(blocked)
      .toRight()
      .setClass('bg-gray-400'),
  };
}

export const COLUMNS = {
  ACTIONS: new Column('ACTIONS', 'actions'),

  ...makeValidBlockedColumnGroup(
    'IMPRESSIONS',
    'impressions',
    {
      accumulated: [ROLES.ADMIN, ROLES.CLIENT, ROLES.AD_OPS],
      valid: [ROLES.ADMIN, ROLES.CLIENT, ROLES.SUPER_ADVERTISER, ROLES.ADVERTISER, ROLES.PUBLISHER, ROLES.AD_OPS],
      blocked: [ROLES.ADMIN, ROLES.CLIENT, ROLES.AD_OPS],
    },
    true
  ),
  ...makeValidBlockedColumnGroup(
    'LEADS',
    'leads',
    {
      accumulated: [ROLES.ADMIN, ROLES.CLIENT, ROLES.AD_OPS, ROLES.SUPER_ADVERTISER, ROLES.ADVERTISER],
      valid: [ROLES.ADMIN, ROLES.PUBLISHER, ROLES.AD_OPS],
      blocked: [ROLES.ADMIN, ROLES.AD_OPS],
    },
    true
  ),
  ...makeValidBlockedColumnGroup(
    'CLICKS',
    'clicks',
    {
      accumulated: [ROLES.ADMIN, ROLES.CLIENT, ROLES.AD_OPS],
      valid: [ROLES.ADMIN, ROLES.CLIENT, ROLES.SUPER_ADVERTISER, ROLES.ADVERTISER, ROLES.PUBLISHER, ROLES.AD_OPS],
      blocked: [ROLES.ADMIN, ROLES.CLIENT, ROLES.AD_OPS],
    },
    true
  ),
  ...makeValidBlockedColumnGroup(
    'CONVERSIONS',
    'conversions',
    {
      accumulated: [ROLES.ADMIN, ROLES.CLIENT, ROLES.SUPER_ADVERTISER, ROLES.ADVERTISER, ROLES.AD_OPS],
      valid: [ROLES.ADMIN, ROLES.PUBLISHER, ROLES.AD_OPS],
      blocked: [ROLES.ADMIN, ROLES.AD_OPS],
    },
    true
  ),
  ...makeValidBlockedColumnGroup(
    'ORDER_VALUE',
    'orderValue',
    {
      accumulated: [ROLES.ADMIN, ROLES.CLIENT, ROLES.SUPER_ADVERTISER, ROLES.ADVERTISER, ROLES.AD_OPS],
      valid: [ROLES.ADMIN, ROLES.PUBLISHER, ROLES.AD_OPS],
      blocked: [ROLES.ADMIN, ROLES.AD_OPS],
    },
    true
  ),

  BREAKDOWN: new Column('', 'breakdown')
    .asSort()
    .asStickyColumn()
    .setAllowedRoles(ROLES.ADMIN, ROLES.CLIENT, ROLES.PUBLISHER, ROLES.AD_OPS, ROLES.SUPER_ADVERTISER, ROLES.ADVERTISER)
    .setClass('font-bold cell-min-width bg-gray-400'),
  CR: new Column('CR', 'cr')
    .asSort(true)
    .setAllowedRoles(ROLES.ADMIN, ROLES.PUBLISHER, ROLES.AD_OPS)
    .toRight()
    .setClass('bg-gray-400'),
  EXPENSE: new Column('EXPENSE', 'expense')
    .asSort(true)
    .setAllowedRoles(ROLES.ADMIN, ROLES.AD_OPS, ROLES.PUBLISHER)
    .toRight()
    .setClass('bg-gray-400'),
  PROFIT: new Column('PROFIT', 'profit')
    .asSort(true)
    .setAllowedRoles(ROLES.ADMIN, ROLES.AD_OPS)
    .toRight()
    .setClass('bg-gray-400'),
  REVENUE: new Column('REVENUE', 'revenue')
    .asSort(true)
    .setAllowedRoles(ROLES.ADMIN, ROLES.AD_OPS)
    .toRight()
    .setClass('bg-gray-400'),
  POST_EVENTS: new Column('POST EVENTS', 'postEvents')
    .setAllowedRoles(ROLES.ADMIN, ROLES.CLIENT, ROLES.SUPER_ADVERTISER, ROLES.ADVERTISER, ROLES.PUBLISHER, ROLES.AD_OPS)
    .setClass('text-center bg-gray-400'),
  GROSS_REVENUE: new Column('GROSS REVENUE', 'grossRevenue')
    .asSort()
    .setAllowedRoles(ROLES.ADMIN)
    .toRight()
    .setClass('bg-gray-400')
    .setVisible(false),
  SUBPUBLISHER_ID: new Column('ID', 'subpublisherId').setClass('bg-gray-400'),
  PUBLISHER: new Column('PUBLISHER', 'publisherName')
    .setAllowedRoles(ROLES.ADMIN, ROLES.CLIENT, ROLES.AD_OPS, ROLES.SUPER_ADVERTISER, ROLES.PUBLISHER)
    .setClass('bg-gray-400'),
  URL: new Column('URL', 'url').setClass('bg-gray-400'),
  CATEGORY: new Column('CATEGORY', 'category')
    .setClass('bg-gray-400')
    .setAllowedRoles([ROLES.ADMIN.id, ROLES.AD_OPS.id, ROLES.CLIENT.id, ROLES.SUPER_ADVERTISER.id]),
};

export const COLUMNS_VALID_BLOCKED = {
  [COLUMNS.IMPRESSIONS.value]: 'impressions',
  [COLUMNS.IMPRESSIONS_VALID.value]: 'impressions',
  [COLUMNS.IMPRESSIONS_BLOCKED.value]: 'impressions',
  [COLUMNS.CLICKS.value]: 'clicks',
  [COLUMNS.CLICKS_VALID.value]: 'clicks',
  [COLUMNS.CLICKS_BLOCKED.value]: 'clicks',
  [COLUMNS.CONVERSIONS.value]: 'conversions',
  [COLUMNS.CONVERSIONS_VALID.value]: 'conversions',
  [COLUMNS.CONVERSIONS_BLOCKED.value]: 'conversions',
  [COLUMNS.LEADS.value]: 'leads',
  [COLUMNS.LEADS_VALID.value]: 'leads',
  [COLUMNS.LEADS_BLOCKED.value]: 'leads',
  [COLUMNS.ORDER_VALUE.value]: 'orderValue',
  [COLUMNS.ORDER_VALUE_VALID.value]: 'orderValue',
  [COLUMNS.ORDER_VALUE_BLOCKED.value]: 'orderValue',
};

export const CONFIG = {
  allColumns: [
    COLUMNS.IMPRESSIONS,
    COLUMNS.IMPRESSIONS_VALID,
    COLUMNS.IMPRESSIONS_BLOCKED,
    COLUMNS.CLICKS,
    COLUMNS.CLICKS_VALID,
    COLUMNS.CLICKS_BLOCKED,
    COLUMNS.CONVERSIONS,
    COLUMNS.CONVERSIONS_VALID,
    COLUMNS.CONVERSIONS_BLOCKED,
    COLUMNS.LEADS,
    COLUMNS.LEADS_VALID,
    COLUMNS.LEADS_BLOCKED,
    COLUMNS.ORDER_VALUE,
    COLUMNS.ORDER_VALUE_VALID,
    COLUMNS.ORDER_VALUE_BLOCKED,
    COLUMNS.REVENUE,
    COLUMNS.GROSS_REVENUE,
    COLUMNS.EXPENSE,
    COLUMNS.PROFIT,
    COLUMNS.CR,
  ],
  defaultColumns: [
    COLUMNS.IMPRESSIONS,
    COLUMNS.CLICKS,
    COLUMNS.CONVERSIONS,
    COLUMNS.LEADS,
    COLUMNS.ORDER_VALUE,
    COLUMNS.REVENUE,
    COLUMNS.EXPENSE,
    COLUMNS.PROFIT,
    COLUMNS.CR,
  ],
  // columns bt user role
  defaultAdvertiserColumns: [
    COLUMNS.IMPRESSIONS_VALID,
    COLUMNS.CLICKS_VALID,
    COLUMNS.CONVERSIONS,
    COLUMNS.LEADS,
    COLUMNS.ORDER_VALUE,
  ],
  defaultPublisherColumns: [
    COLUMNS.IMPRESSIONS_VALID,
    COLUMNS.CLICKS_VALID,
    COLUMNS.CONVERSIONS_VALID,
    COLUMNS.LEADS_VALID,
    COLUMNS.ORDER_VALUE_VALID,
    COLUMNS.EXPENSE,
    COLUMNS.CR,
  ],
  breakdown: {
    name: 'Group By',
    value: 'groupBy',
    items: [
      {
        name: 'Offer',
        value: BREAKDOWN_VALUES.offer,
        items: {
          name: 'By Offer',
          value: 'byOffer',
          items: [
            { name: 'Date', value: BREAKDOWN_VALUES.day, sort: { field: 'day', order: 'desc' } },
            {
              name: 'Publisher',
              value: BREAKDOWN_VALUES.publisher,
              rolesAllowed: [ROLES.ADMIN.id, ROLES.CLIENT.id, ROLES.PUBLISHER.id, ROLES.AD_OPS.id],
            },
            { name: 'Creativity Type', value: BREAKDOWN_VALUES.creative_type },
            { name: 'Creativity Name', value: BREAKDOWN_VALUES.creative },
          ],
        },
        rolesAllowed: [
          ROLES.ADMIN.id,
          ROLES.CLIENT.id,
          ROLES.SUPER_ADVERTISER.id,
          ROLES.ADVERTISER.id,
          ROLES.PUBLISHER.id,
          ROLES.AD_OPS.id,
        ],
      },
      {
        name: 'Day',
        value: BREAKDOWN_VALUES.day,
        items: {
          name: 'By Day',
          value: 'byDay',
          items: [
            {
              name: 'Publisher',
              value: BREAKDOWN_VALUES.publisher,
              rolesAllowed: [ROLES.ADMIN.id, ROLES.CLIENT.id, ROLES.PUBLISHER.id, ROLES.AD_OPS.id],
              sort: { field: 'revenue', order: 'desc' },
            },
            { name: 'Offer', value: BREAKDOWN_VALUES.offer, sort: { field: 'revenue', order: 'desc' } },
            { name: 'Hour', value: BREAKDOWN_VALUES.hour, sort: { field: 'hour', order: 'desc' } },
            {
              name: 'Creativity Type',
              value: BREAKDOWN_VALUES.creative_type,
              sort: { field: 'revenue', order: 'desc' },
            },
            { name: 'Creativity Name', value: BREAKDOWN_VALUES.creative, sort: { field: 'revenue', order: 'desc' } },
          ],
        },
        rolesAllowed: [
          ROLES.ADMIN.id,
          ROLES.CLIENT.id,
          ROLES.SUPER_ADVERTISER.id,
          ROLES.ADVERTISER.id,
          ROLES.PUBLISHER.id,
          ROLES.AD_OPS.id,
        ],
      },
      {
        name: 'Hour',
        value: BREAKDOWN_VALUES.hour,
        rolesAllowed: [
          ROLES.ADMIN.id,
          ROLES.CLIENT.id,
          ROLES.SUPER_ADVERTISER.id,
          ROLES.ADVERTISER.id,
          ROLES.PUBLISHER.id,
          ROLES.AD_OPS.id,
        ],
      },
      {
        name: 'Month',
        value: BREAKDOWN_VALUES.month,
        rolesAllowed: [
          ROLES.ADMIN.id,
          ROLES.CLIENT.id,
          ROLES.SUPER_ADVERTISER.id,
          ROLES.ADVERTISER.id,
          ROLES.PUBLISHER.id,
          ROLES.AD_OPS.id,
        ],
      },
      {
        name: 'Publisher',
        value: BREAKDOWN_VALUES.publisher,
        items: {
          name: 'By Publisher',
          value: 'byPublisher',
          items: [
            { name: 'Date', value: BREAKDOWN_VALUES.day, sort: { field: 'day', order: 'desc' } },
            { name: 'Offer', value: BREAKDOWN_VALUES.offer },
            { name: 'Creativity Type', value: BREAKDOWN_VALUES.creative_type },
            { name: 'Creativity Name', value: BREAKDOWN_VALUES.creative },
          ],
        },
        rolesAllowed: [ROLES.ADMIN.id, ROLES.CLIENT.id, ROLES.PUBLISHER.id, ROLES.AD_OPS.id, ROLES.SUPER_ADVERTISER.id],
      },
      {
        name: 'PubId',
        value: BREAKDOWN_VALUES.pubId,
        rolesAllowed: [ROLES.ADMIN.id, ROLES.CLIENT.id, ROLES.PUBLISHER.id, ROLES.AD_OPS.id, ROLES.SUPER_ADVERTISER.id],
      },
      {
        name: 'Advertiser',
        value: BREAKDOWN_VALUES.advertiser,
        items: {
          name: 'By Advertiser',
          value: 'byAdvertiser',
          items: [
            { name: 'Date', value: BREAKDOWN_VALUES.day, sort: { field: 'day', order: 'desc' } },
            { name: 'Offer', value: BREAKDOWN_VALUES.offer },
            {
              name: 'Publisher',
              rolesAllowed: [ROLES.ADMIN.id, ROLES.CLIENT.id, ROLES.PUBLISHER.id, ROLES.AD_OPS.id],
              value: BREAKDOWN_VALUES.publisher,
            },
          ],
        },
        rolesAllowed: [
          ROLES.ADMIN.id,
          ROLES.CLIENT.id,
          ROLES.SUPER_ADVERTISER.id,
          ROLES.ADVERTISER.id,
          ROLES.PUBLISHER.id,
          ROLES.AD_OPS.id,
        ],
      },
      {
        name: 'Creativity Name',
        value: BREAKDOWN_VALUES.creative,
        items: {
          name: 'By Creative',
          value: 'byCreative',
          items: [
            { name: 'Date', value: BREAKDOWN_VALUES.day, sort: { field: 'day', order: 'desc' } },
            {
              name: 'Publisher',
              rolesAllowed: [ROLES.ADMIN.id, ROLES.CLIENT.id, ROLES.PUBLISHER.id, ROLES.AD_OPS.id],
              value: BREAKDOWN_VALUES.publisher,
            },
          ],
        },
        rolesAllowed: [
          ROLES.ADMIN.id,
          ROLES.CLIENT.id,
          ROLES.SUPER_ADVERTISER.id,
          ROLES.ADVERTISER.id,
          ROLES.PUBLISHER.id,
          ROLES.AD_OPS.id,
        ],
      },
      {
        name: 'Creativity Type',
        value: BREAKDOWN_VALUES.creative_type,
        items: {
          name: 'By Creative Type',
          value: 'byCreativeType',
          items: [
            { name: 'Creative Name', value: BREAKDOWN_VALUES.creative },
            { name: 'Date', value: BREAKDOWN_VALUES.day, sort: { field: 'day', order: 'desc' } },
            {
              name: 'Publisher',
              rolesAllowed: [ROLES.ADMIN.id, ROLES.CLIENT.id, ROLES.PUBLISHER.id, ROLES.AD_OPS.id],
              value: BREAKDOWN_VALUES.publisher,
            },
          ],
        },
        rolesAllowed: [
          ROLES.ADMIN.id,
          ROLES.CLIENT.id,
          ROLES.SUPER_ADVERTISER.id,
          ROLES.ADVERTISER.id,
          ROLES.PUBLISHER.id,
          ROLES.AD_OPS.id,
        ],
      },
      {
        name: 'Category',
        value: BREAKDOWN_VALUES.subPublisherCategory,
        rolesAllowed: [ROLES.ADMIN.id, ROLES.AD_OPS.id, ROLES.CLIENT.id, ROLES.SUPER_ADVERTISER.id],
      },
    ],
  },
  logFilters: [FILTERS_CONFIG.OFFER_ID2, FILTERS_CONFIG.CREATED_AT, FILTERS_CONFIG.AUTHOR_ID],
  filters: [
    FILTERS_CONFIG.COUNTRY_ISO,
    FILTERS_CONFIG.PUBLISHER_ID,
    FILTERS_CONFIG.ANALYTICS_ADVERTISER_ID,
    FILTERS_CONFIG.OFFER_ID,
    FILTERS_CONFIG.CAMPAIGN_ID,
    FILTERS_CONFIG.SUB_PUBLISHER_CATEGORY,
  ],
  filtersNameToLogReport: {
    [FILTERS_CONFIG.PUBLISHER_ID.key]: 'pb',
    [FILTERS_CONFIG.ANALYTICS_ADVERTISER_ID.key]: 'ad',
    [FILTERS_CONFIG.OFFER_ID.key]: 'of',
    [FILTERS_CONFIG.CAMPAIGN_ID.key]: 'camp',
  },
  blockedTypes: {
    IMPRESSION: 'imp',
    CLICK: 'clk',
    CONVERSION: 'conv_pev',
    POST_EVENT: 'pev',
    LEADS: 'conv',
  },
  defaultDate: { startDate: addDays(TODAY, -7), endDate: TODAY },
  limitMaxDate: TODAY,
  limitMinDate: LIMIT_MIN_DATE,
  dateList: [
    {
      title: 'Today',
      startDate: TODAY,
      endDate: TODAY,
    },
    {
      title: 'Yesterday',
      startDate: addDays(TODAY, -1),
      endDate: addDays(TODAY, -1),
    },
    {
      title: 'Last 7 days',
      startDate: addDays(TODAY, -7),
      endDate: TODAY,
    },
    {
      title: 'Last 14 days',
      startDate: addDays(TODAY, -14),
      endDate: TODAY,
    },
    {
      title: 'Last 30 days',
      startDate: addDays(TODAY, -30),
      endDate: TODAY,
    },
    {
      title: 'This week',
      startDate: CURRENT_WEEK.startDate,
      endDate: TODAY,
    },
    {
      title: 'This month',
      startDate: CURRENT_MONTH.startDate,
      endDate: TODAY,
    },
    {
      title: 'Last week',
      startDate: LAST_WEEK.startDate,
      endDate: LAST_WEEK.endDate,
    },
    {
      title: 'Last month',
      startDate: LAST_MONTH.startDate,
      endDate: LAST_MONTH.endDate,
    },
  ],
  tableSortOptions: {
    REVENUE: 'revenue',
    DAY: 'day',
    MONTH: 'month',
    HOUR: 'hour',
  },
};

export const CHART_CONFIG = {
  chartDataType: {
    IMPRESSIONS: 'impressions',
    CLICKS: 'clicks',
    CONVERSIONS: 'conversions',
    REVENUE: 'revenue',
  },
  chartOptions: [
    new SelectOption('clicks', 'Clicks'),
    new SelectOption('conversions', 'Conversions'),
    new SelectOption('revenue', 'Revenue'),
  ],
  chartLabelFormattersByBreakdown: {
    [BREAKDOWN_VALUES.day]: val => {
      if (!val) return null;
      const [year, month, day] = val.split('-');
      return `${day}/${month}/${year}`;
    },
    [BREAKDOWN_VALUES.month]: val => {
      const date = stringToDate(val);
      return applyDateFormatters(date, monthDateFormatter);
    },
    [BREAKDOWN_VALUES.hour]: val => val,
  },
};

/** LOGS CONFIG */

export const LOGS_COLUMNS = [
  new Column('REQUESTED AT', 'requestedAt'),
  new Column('REQUESTED BY', 'requestedBy'),
  new Column('DATE FROM', 'dateFrom'),
  new Column('DATE TO', 'dateTo'),
  new Column('REQUESTED REPORT', 'requestedReport'),
  new Column('FILTERS APPLIED', 'filtersApplied'),
  new Column('ACTIONS', 'actions').setClass('text-center w-4'),
];
